import React from "react";



export const Body = () => {
  return (
    <>
      <div className="main__body__wrap left__right__space">
        <div className="dashboard__body__wrap">
          <h3 className="account__head mb__30">
          Terms & Conditions
          </h3>
        </div>
        <div className="live__heightlight mb__30">
        <div className="section__title">
              <h4 style={{ color: "white" }}>Terms and Conditions for MyLotto Kenya</h4>
            </div>
          <div className="heightlight__tab">
            <div className="nav b__bottom" id="nav-tabheight" role="tablist">
              <div className="container">
                <h5 className="text-white">Edition effective 12th August 2021</h5>
                <div className="row mb-2 text-white">
                Dynamic Mobile Communications is authorized and regulated by the Betting Control and Licensing Board of Kenya (hereinafter referred to as “BCLB”) under the Betting, Lotteries and Gaming Act, Cap 131, Laws of Kenya under License number: 0000898. <br />

All Dynamic Mobile Communications Games remain subject to the provisions of the Betting Lotteries and Gaming Act (Cap 131 Laws of Kenya) and any disputes arising hereunder shall be resolved in accordance with such provision. <br />

This agreement establishes the Terms and Conditions that govern the contractual relationship Ticket ween the Company and the Player and shall apply when You play any Dynamic Mobile Communications Game via any media or electronic platform, including inter alia, mobile devices, tablets, laptops and computers. You are required to accept these Terms and Conditions when interacting with the services offered by the Company and you are bound by these Terms and Conditions throughout the existence of the relationship. The Terms and Conditions govern the way we do business, how we take and settle Tickets, and how we identify what the Player can expect from Dynamic Mobile Communications. By using our services, regardless of how a Player accesses them, the Player acknowledges and confirms acceptance of these terms and agrees to abide and be bound by them. <br />

Dynamic Mobile Communications will on occasion run additional bonus prize draws, jackpots and Raffles at its discretion. Information relating to such draws will be made available on the Site when relevant. Such draws and jackpots may be subject to additional game specific rules, terms, and conditions. <br />
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>METHODS OF ENTRY</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                    <h5 style={{ color: "white" }}>WEB:</h5>
                  A Player is able to enter the Lotto Game via the following URL: <br />

                  <a href='/'>Home</a> <br />
                    Lucky Pick; Numbers will be randomly generated on behalf of the Player <br />
                    Number Selection; The Player is able to select their own numbers for the Lotto Game <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>MOBILE MONEY</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  Players are able to enter the Lotto Game through their Mobile Money Account by purchasing an Entry. <br />

                    To purchase an Entry via Mobile Money, a Player must make a minimum payment of Ksh50 to Paybill Number <b>382777</b> <br />

                    The user is responsible for any financial transaction associated with the purchase of the Mobile Money Entry. <br />

                    Upon confirmation that the transaction has been approved the Player is sent their Ticket via SMS. <br />

                    Notes: <br />

                        i. Safaricom’s “MPESA” and Airtel’s “Airtel Money” are the only Mobile Money platforms currently available for use. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>SMS:</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  Players can initiate an Entry in to the Lotto Game via SMS by texting a Keyword (E.G. LOTTO) to  <b>382777</b>. <br />

There are a number of different options for initiating an Entry via SMS, for example: <br />

    When the Player wishes to select their own numbers they SMS the allocated KEYWORD + X X X X X X X (being their number selection) to  <b>382777</b> <br />
    When the Player opts for a random number selection the SMS the allocated KEYWORD to  <b>382777</b> in order to automatically receive their selection. <br />
    Players can also elect to enter the numbers they selected (or were allocated in their last entry by sending LAST to  <b>382777</b> <br />

Notes: <br />

    i. If the Player has insufficient funds in their Account, then they will be prompted to make a deposit via Mobile Money to complete their Entry <br />
    ii. Players wishing to select their own numbers must first turn the auto-buy feature off via the website mylottokenya.co.ke or by calling the Customer Support Team (0743000011) <br />
    iii. A new account is automatically established for each new user on their first entry at which time details of this account are sent out to that user <br />

Additional steps based on prior registration include Age Verification and Acceptance of T&Cs. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>
              The LOTTO Game Rules
              </h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  1.1 The LOTTO involves a random number draw selecting six numbers selected from the numbers between 0 to 39. <br />

                    1.2 The Draw uses a Random Number Generator approved by the BCLB. <br />

                    1.3 Daily Draws are hourly on Sunday, Monday, Tuesday, Thursday and Friday. The Main draws are scheduled every Wednesday and Saturday at 9pm. <br />

                    The exact draw time will depend on the processing of the entries such that Dynamic Mobile Communications can fulfil its obligations to the BCLB. <br />

                    1.4 The cost for each LOTTO Ticket is between Ksh50 and Ksh2,000. <br />

                    1.5 In the event that a Player deposits less than the minimum Ticket, Lotto <br />

                    shall hold the funds on account, shall notify the Player of the same via SMS, and shall advise that upon depositing the balance of a minimum Ticket, the player shall be entered into the next relevant draw. <br />

                    1.6 If a Player attempts to Ticket over the default maximum amount, at the Company’s discretion, Player may be automatically refunded the difference less the cost of the Mobile Money transaction. <br />

                    1.7 The deadline for LOTTO Tickets will be decided by Dynamic Mobile Communications and is currently set to 30 seconds before the draw, and will be displayed on the Site. <br />

                    1.8 There are many ways to win, as outlined in the Game Description above. <br />

                    1.9 Only one Prize can be won per LOTTO Ticket, which will be the highest of the available Prize Categories achieved by, and relevant to, that LOTTO Ticket. <br />

                    1.12 Winning the Lottery Ticket will be that announced on the Lotto website and the Media with respect to that particular draw. <br />

                    1.13 The results can be checked by visiting the Site. These results will also be sent to all players that made a Ticket in the game. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>
              Rules to Entry
              </h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  2.1 You must have a Kenyan Mobile Money Account registered to Your Account and have the valid form of Identification Information that is associated with the Kenyan Mobile Money Account. <br />

                    2.2 Each Ticket must be within the Ksh 50- Ksh 2,000. <br />

                    2.3 Tickets can only be made through Dynamic Mobile Communications. <br />

                    2.4 If a Ticket is made after the designated draw deadline (as advertised on the Site), the Ticket is automatically entered into the next scheduled draw. For example: <br />

                        a) If a Ticket is received after the advertised deadline, the Ticket will be entered into the following draw <br />
                        b) If a Ticket is received after the advertised deadline due to a delay in Dynamic Mobile Communications Ventures or computer systems, or any unforeseen technical difficulties, the Ticket will be entered into the following draw <br />

                    2.5 Your Ticket will only be valid if it is recorded on RNG Computer System. <br />

                    2.6 In the event that You have deposited money into Dynamic Mobile Communications’s Mobile Money account to complete a Ticket transaction but, due to unforeseen technical difficulties, issues or problems, Dynamic Mobile Communications is unable to process your deposit and issue a Ticket at that time, Dynamic Mobile Communications will process Your deposit and issue a Ticket for the next available draw upon rectification of the technical difficulties. <br />

                    2.7 You can only Ticket and receive Prizes in line with these Rules.

                    2.8 There is no general right for a person to Ticket. Dynamic Mobile Communications can refuse to allow Tickets to anyone, and/or limit the number of Tickets You can place, without providing an explanation or reason. <br />

                    2.9 Subject to Rule 2.8, the following people cannot Ticket and thus Dynamic Mobile Communications will not be liable to pay a Prize to them: <br />

                        a) Anyone under the age of 18, whether that person is Ticketing for themselves or anyone else <br />
                        b) Directors and employees of Dynamic Mobile Communications, Any key contractors, or sub-contractors <br />
                        c) Personal partners and persons who are, to some extent, maintained by anyone mentioned in Rules 2.9 if living in the same household as that person <br />
                        d) Any person who has an Account which has a status that prohibits them from Ticket or receiving a Prize (including without limitation a person whose Account has been suspended or terminated); <br />

                    e)Dynamic Mobile Communications may decide and specify in any amendment which is made to these Rules or in any other appropriately published communication from time to time. <br />

                    2.10 If a Prize is paid to anyone listed in Rule 2.9, that person will be required to repay the Prize immediately. <br />

                    2.11 With the BCLB’s written consent, Dynamic Mobile Communications or Dynamic Mobile Communications employees may buy a Ticket but only for the purpose of testing Accounts and purchases using Mobile Money Accounts. The BCLB may impose conditions on any consent granted including, for example, that Dynamic Mobile Communications employees and Dynamic Mobile Communications will not be entitled to benefit from any Prize won by any such Tickets. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>Your Responsibilities</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  3.1 You are responsible for keeping safe and confidential any information required to claim a Prize for a Ticket (including, for example, the relevant Ticket Number and Your Identification Information). You acknowledge that if You are not able to provide sufficient Identification Information when claiming a Prize, You will not receive that Prize <br />

                    3.2 You are also responsible for: <br />

                        Checking that Your Ticket shows the correct Ticket, Games and Draw dates; and <br />
                        Claiming (where applicable) and checking You receive the full amount of any Prize You are entitled to. <br />
                        In the event that there are any taxes applicable to the Prizes, Dynamic Mobile Communications will deduct the relevant amount and remit to the tax collection agency, withholding tax of 20% shall be deducted from winnings. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>Prize Terms and Rules<br />
              Daily Draw;</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  4.1. If there is a Ticket that matches the drawn 6 numbers and that Ticket was made at the minimum level (Currently Ksh50), the following table outlines the pay-out for that winning Ticket:
                  </div>
                  <div className="row mb-2 text-white">
                    <table>
                        <th>Draw</th>
                        <th>Payout Multiple</th>
                        <tr>
                            <td>Match 6</td>
                            <td>20,000</td>
                        </tr>
                        <tr>
                            <td>Match 5</td>
                            <td>800</td>
                        </tr>
                        <tr>
                            <td>Match 4</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>Match 3</td>
                            <td>3</td>
                        </tr>
                    </table>
                  </div>
                  <div className="row mb-2 text-white">
                  4.2. Example: if there is a Ticket on 6 digits that matches the drawn numbers and that Ticket was made Ticket between the minimum stake level shown above, and the maximum stake level (currently KSH2,000), the pay-out shall escalate accordingly.The winning amounts will adjust according to the multipliers listed in clause 4.1.
                  </div>
                  <div className="row mb-2 text-white">
                    <table>
                        <th>Your Ticket</th>
                        <th>Your Numbers</th>
                        <th>Winning Numbers</th>
                        <th>Shared</th>
                        <th>You Would Win</th>
                        <tr>
                            <td>50</td>
                            <td>0,39,27,7,9,10</td>
                            <td>0,39,27,7,9,10</td>
                            <td>Yes</td>
                            <td>1,000,000</td>
                        </tr>
                        <tr>
                            <td>50</td>
                            <td>0,39,27,7,9,12</td>
                            <td>0,39,27,7,9,10</td>
                            <td>No</td>
                            <td>40,000</td>
                        </tr>
                        <tr>
                            <td>50</td>
                            <td>0,39,27,7,8,11</td>
                            <td>0,39,27,7,9,10</td>
                            <td>No</td>
                            <td>1,000</td>
                        </tr>
                        <tr>
                            <td>50</td>
                            <td>0,39,27,8,4,13</td>
                            <td>0,39,27,7,9,10</td>
                            <td>No</td>
                            <td>150</td>
                        </tr>
                        <th>Maximum Play</th>
                        <tr>
                            <td>2,000</td>
                            <td>0,39,27,7,9,10</td>
                            <td>0,39,27,7,9,10</td>
                            <td>Yes</td>
                            <td>40,000,000</td>
                        </tr>
                        <tr>
                            <td>2,000</td>
                            <td>0,39,27,7,9,14</td>
                            <td>0,39,27,7,9,10</td>
                            <td>No</td>
                            <td>1,600,000</td>
                        </tr>
                        <tr>
                            <td>2,000</td>
                            <td>0,39,27,7,2,14</td>
                            <td>0,39,27,7,9,10</td>
                            <td>No</td>
                            <td>40,000</td>
                        </tr>
                        <tr>
                            <td>2,000</td>
                            <td>0,39,27,8,2,11</td>
                            <td>0,39,27,7,9,10</td>
                            <td>No</td>
                            <td>6,000</td>
                        </tr>
                    </table>
                  </div>
                  <div className="row mb-2 text-white">
                  The Company reserves the right to review and adjust the above at its sole discretion but shall amend accordingly in these terms and republish. Players are assumed to have reviewed the most up-to-date terms and conditions published on the website in advance of playing. <br />

                    4.3 Megadraw <br />

                        1. This is a Match 6 draw with a multiplying factor of 100,000. <br />
                        2. The Megadraw is carried out on Wednesday and Saturday. <br />
                    3.  Megadraw entries consist of valid tickets purchased between Saturday 2100hrs to Wednesday 2059 for the Wednesday Megadraw and Wednesday 2100hrs to Saturday 2059hrs for the Saturday Megadraw. <br />
                    4. *Time in EAT <br />
                    5. The Megadraw is a shared prize. <br />
                  </div>
                  <div className="row mb-2 text-white">
                    <table>
                        <th>Stake</th>
                        <th>Your Picks</th>
                        <th>Winning Numbers</th>
                        <th>Multiplier</th>
                        <th>Winnings</th>
                        <tr>
                            <td>Kshs 50</td>
                            <td>0,39,27,7,9,14</td>
                            <td>0,39,27,7,9,14</td>
                            <td>100,000</td>
                            <td>Kshs 5,000,000</td>
                        </tr>
                        <tr>
                            <td>Kshs 100</td>
                            <td>0,39,27,7,9,14</td>
                            <td>0,39,27,7,9,14</td>
                            <td>100,000</td>
                            <td>Kshs 10,000,000</td>
                        </tr>
                        <tr>
                            <td>Kshs 300</td>
                            <td>0,39,27,7,9,14</td>
                            <td>0,39,27,7,9,14</td>
                            <td>100,000</td>
                            <td>Kshs 30,000,000</td>
                        </tr>
                        <tr>
                            <td>Kshs 500</td>
                            <td>0,39,27,7,9,14</td>
                            <td>0,39,27,7,9,14</td>
                            <td>100,000</td>
                            <td>Kshs 50,000,000</td>
                        </tr>
                        <tr>
                            <td>Kshs 2,000</td>
                            <td>0,39,27,7,9,14</td>
                            <td>0,39,27,7,9,10</td>
                            <td>Yes</td>
                            <td>Kshs 200,000,000</td>
                        </tr>
                        
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>Claiming a Prize</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  5.1. You can only claim a Prize if You are the holder of an Account with a Winning Ticket. <br />

                    5.2. Interactive Ticket Slips cannot be used as evidence of a Ticket or of the numbers selected for a Game, or used otherwise to claim a Prize, nor can a purchase confirmation made available to You (or sent to You) by Dynamic Mobile Communications be used under any circumstances as evidence that You are entitled to a Prize. <br />

                    5.3. A Prize can be claimed in person within seven days from the end of the Claim Period if, before the end of the Claim Period, You contact Dynamic Mobile Communications and notify it that You intend to claim a Prize in this way (and give Your name, address and all details of the Ticket that Dynamic Mobile Communications reasonably requires) and if You then present the proof of identity and any relevant completed Prize Claim Form in person at Dynamic Mobile Communications’s office during normal business hours (see Rule 5.4) within the seven day period. You can contact Dynamic Mobile Communications by: <br />

                        1. a) Telephoning the Customer Care line 0703 065 032. Which is available 24hours <br />
                        2. b) Emailing info@mezizlotto.com Dynamic Mobile Communications must receive the email before the end of the Claim Period. <br />
                        <b>3. c) If You fail to claim within the additional twenty eight day period, Your entitlement to the Prize will be lost and the Prize will not be PAID.  </b><br />

                    5.4. You can only claim for the highest Prize Category Your Ticket has won in. You cannot claim a Prize for a lower Prize tier, or for any otherwise unclaimed Prize in that Draw or Prize pool. <br />

                    5.5. All unclaimed prizes (three) months after the end of the Claim Period will be surrendered to the BCLB. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>Validation Requirements</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  6.1. Before a Prize can be paid on a Ticket, it must be successfully validated in line with Dynamic Mobile Communications’s reasonable validation procedures adopted from time to time. Dynamic Mobile Communications’s decision about whether the Ticket is valid will be final and binding. <br />

6.2. Without limiting the effect of Rule 6.1, Dynamic Mobile Communications will declare a Ticket invalid (and will not, therefore, pay any Prize relating to that Ticket) if: <br />

    1. a) The Ticket is the result of an act by You or another person that was intended to increase the chances of You or that person winning a Prize in the relevant Game above the chances enjoyed by other Players of that Game, or to increase that Prize; or <br />
   2.  b) The Ticket is counterfeit, all or part of the Ticket has been forged, or the Ticket fails to pass Dynamic Mobile Communications’s validation and security checks; or <br />
   3.  c) The Prize claim is not received within the relevant claim period set out in Rules 5.3 and 5.5; <br />
   4.  d) The Ticket Number for the Ticket is not on Dynamic Mobile Communications’s official list of Winning Entries, or the relevant Prize for the Winning Ticket with that Ticket Number has been paid previously; or <br />
   5.  e) The details associated with the Ticket Number of a Ticket do not match Dynamic Mobile Communications’s official records of that Ticket Number; or <br />
    6. f) Dynamic Mobile Communications reasonably believes that the Ticket was purchased by or on behalf of a person falling within one of the categories in Rule 2.8. <br />

6.3. Without limiting the effect of Rule 6.1, Dynamic Mobile Communications may declare a Ticket invalid (and will not be obliged to pay any Prize) if: <br />

    1. a) Dynamic Mobile Communications reasonably believes that the person claiming the Prize is not the holder of the relevant Account or that person’s duly authorised representative, or that the information provided by the person claiming the Prize is incomplete or has been altered or tampered with. <br />
    2. b) The Ticket has not been issued or sold by Dynamic Mobile Communications or any of its agents. <br />
    3. c) The Ticket Number (or any other unique feature of the Ticket) does not match the records on Dynamic Mobile Communications’s Computer System for the Game that the Ticket relates to. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>Payment of Prizes</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  7.1. The Information below sets out how Interactive Prizes of different amounts should be claimed and will be paid. <br />

                   1. a) Prizes ranging in value from ‘Ksh100 to Ksh150,000’: Prizes and other winnings from Games in this category will automatically be deposited into Your Mobile Money Account. <br />

                    7.2. Dynamic Mobile Communications reserves the right to: <br />

                    2. a) Pay Prizes by cheque, bank transfer, direct credit, or Mobile Money. If payment is made for Prizes of Ksh.150,000 and less, this will be deposited minus the disbursement costs to the Mobile Money Account registered to Your Account <br />
                    3. b) Ask You to attend Dynamic Mobile Communications’s Office to claim Your Prize. <br />
                    4. c) Withhold a Prize, providing it is acting reasonably, until it is entirely satisfied that a Ticket is valid and that the claim has been made in good faith <br />
                    5. d) Withhold a Prize (or seek or recover a Prize already paid) until all appropriate checks and enquiries have been carried out, if in its discretion it believes, on reasonable grounds, that You (as the person claiming a Prize) are: not lawfully entitled to receipt of a Prize; or that a Prize has been paid to You in circumstances where Dynamic Mobile Communications decides (in line with Rule 11.1) that the Ticket is invalid or defective; or where there is any other reason for Dynamic Mobile Communications (acting reasonably) to question Your entitlement to the Prize. Any checks and enquiries will be carried out promptly by Dynamic Mobile Communications You shall repay immediately upon demand any Prize Dynamic Mobile Communications seeks to recover <br />
                    6. e) Recover a Prize incorrectly paid into Your Account <br />
                    7. f) Request proof of entitlement to a Prize (including, without limitation, Identification Information, proof of identity and capacity to claim) <br />
                    8. g) Refuse to pay a Prize If You are unable or fail to produce sufficient Identification Information when claiming a Prize <br />
                    9. h) Photograph and Publicize winners claiming a Prize for Dynamic Mobile Communications’s records <br />
                    10. g) Refuse to pay a prize if You refuse to sign all the documentation required by the Insurance Underwriters <br />

                    7.3. The right to a Prize is not transferable. <br />

                    7.4. Dynamic Mobile Communications will not be responsible or liable to any person claiming a Prize which has been paid to a person who assumed the identity of the relevant Account. <br />

                    7.5. No interest will be payable on any Prize, including without limitation: whilst any checks or enquiries are being carried out in relation to a Winning Ticket; whilst a dispute about the Ticket or Prize claim is being resolved; or for the period a Prize remains unclaimed. <br />

                    7.6. Any charges levied by the Mobile Operators’ payment gateways (Mpesa) will be deducted from Your account. <br />

                    7.7. Any applicable taxes levied by the Kenya Revenue Authority will be deducted, all winnings are subject to 20% withholding tax. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>
              Draws and Winning Numbers
              </h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  9.1. When You claim or are paid a prize, You will automatically be deemed to grant to Dynamic Mobile Communications an irrevocable right to publish, through all types of media broadcasting, including the internet, for the purposes of promoting the win, Your full name (as well as Your nick name), hometown, photograph, and video materials without any claim for broadcasting, printing or other rights, for a period of up to thirty-six months from the date of prize award. You also give Dynamic Mobile Communications the right to publish, for a period of up to thirty-six months from the date of the prize award, any additional information that You voluntarily provide. You shall have no claim against Dynamic Mobile Communications for invasion of privacy or on any other ground(s) by reason of the publication or broadcasting of Your Name, hometown, photograph, video materials, or information voluntarily provided by You. <br />

                    9.2. If You win a Prize, You understand and accept that Dynamic Mobile Communications may, if required by law, provide a third party with details of any Prize claimed by You or on Your behalf, or paid to You. <br />

                    9.3. By participating in the Game, You expressly consent to the collection, use and disclosure by Dynamic Mobile Communications, its employees, agents and/or service providers, of the Participant’s Personal Information, for the purposes of conducting and administering the Game. <br />

                    9.4. You accept that Dynamic Mobile Communications has the right to send you promotional messages unless You opt out. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>Draw Closures etc.</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  10.1. Dynamic Mobile Communications can, following consultation with the BCLB, announce the closing date for an individual Game at any time. No Entries for that Game will be sold after the closing date or time. <br />

                    10.2. Dynamic Mobile Communications can also (at its absolute discretion) suspend or withdraw any Game following consultation with the BCLB.
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
              <h4 style={{ color: "white" }}>Limitation of Liability</h4>
            </div>
            <div className="heightlight__tab">
              <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                <div className="container">
                  <div className="row mb-2 text-white">
                  11.1. Where instructed by the BCLB, Dynamic Mobile Communications will, or may (in its discretion -provided it is acting reasonably), declare that a Ticket is defective. In these circumstances, all relevant Entries and all relevant Prizes won will be void and Dynamic Mobile Communications will either: <br />

                    11. a) Provide You with an opportunity to place another Ticket of equivalent price; or <br />
                    12. b) Refund the amount paid for the defective Ticket. <br />

                    Dynamic Mobile Communications will decide which of (a) or (b) above shall apply. <br />

                    You do not have the right to cancel a Ticket. Subject to Rule 11.1 (b) and Rule 12.1, no refunds will be given in any circumstances. No interest will be payable in respect of any refunds made. <br />

                    11.2. If Dynamic Mobile Communications is fully satisfied after proper and careful consideration and enquiries that the cancellation of a Ticket was wholly and directly the result of Dynamic Mobile Communications’s fraud, negligence, or error, and that Ticket would, but for that cancellation, have been a Winning Ticket, then Dynamic Mobile Communications will not refund the cost of the Ticket and Dynamic Mobile Communications’s only liability will be to pay an amount equal to the Prize that You would have been entitled to if the cancellation did not take place. <br />

                    11.3. Dynamic Mobile Communications’s only obligation is to pay the Prizes won in any Game to the rightful owners of Winning Entries or provide a refund in the circumstances set out in these Rules. Without limiting the effect of Rules 11.1 and 11.5, Dynamic Mobile Communications will not be liable in any circumstances for any loss of whatever nature other than, subject to Rule 11.4, the non-payment of a Prize or the non-payment of a refund You are entitled to under these Rules. In particular, but without limitation, Dynamic Mobile Communications will not be liable for any loss of profits, or any special, indirect or consequential loss, suffered or incurred by You (or any holder or owner of a Ticket, any person claiming a Prize during the Claim Period, or any other person) that arises out of the withdrawal from any Game, or from the participation or non-participation of You or any person in any Game. This includes the loss, for whatever reason, of the chance to participate in that Game. <br />
                  </div>
                </div>
              </div>
            </div>

            <div className="section__title">
                <h4 style={{ color: "white" }}>
                11.4. Dynamic Mobile Communications will not be liable to any person for:
                </h4>
            </div>
            <div className="heightlight__tab">
                <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                    <div className="container">
                        <div className="row mb-2 text-white">
                            
                            13. a) Events beyond its reasonable control and expectations (for example war, strike, lockout, industrial action, fire, flood, drought, power cut and/or failure or obstruction of any network, broadcasting or telecommunications service); <br />
                            14. b) The failure or destruction of, or damage to, all or part of the computer systems or records of Dynamic Mobile Communications or any third party (including, without limitation, the Site and/or Dynamic Mobile Communications’s Computer System); <br />
                            15. c) Any mistakes caused by all or part of the computer systems or records of Dynamic Mobile Communications or any third party (including, without limitation, the Site and/or Dynamic Mobile Communications’s Computer System); <br />
                            16. d) Delays, losses, mistakes, or omissions in or made by the postal or other delivery service, or by the banking system;
                            17. e) Any other action or event which prevents or hinders the issuing of a valid Ticket (including without limitation the failure of the Site to display correctly on any device used to view it); <br />
                            18. f) The refusal to allow any person to Ticket or to allow any person to play a Game Played Online or via SMS or Mobile Money;
                            19. g) Any losses caused by You, including the misuse or unauthorised use of passwords, money lost by playing the Games Played Online or via SMS, and the failure to keep Dynamic Mobile Communications informed of changes to Your contact details; <br />
                            20. h) Any losses caused by the failure or malfunction of Your: equipment or technology; internet service provider; mobile phone network operator; equipment, technology; or the internet service provider or mobile phone network operator of any other person or third party. <br />

                        </div>
                    </div>
                </div>
            </div>

            <div className="section__title">
                <h4 style={{ color: "white" }}>
                Disputes and Dynamic Mobile Communications’s Decisions
                </h4>
            </div>
            <div className="heightlight__tab">
                <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                    <div className="container">
                        <div className="row mb-2 text-white">
                            
                        12.1. Dynamic Mobile Communications’s decision about whether or not a Ticket is a Winning Ticket, or in relation to any other matter or dispute arising from the payment or non-payment of Prizes, will be final and binding, provided that it is a reasonable decision (and subject to Rule 12.3). Without limiting the effect of the previous sentence and Rule 11.1, following any such decision made by Dynamic Mobile Communications, Dynamic Mobile Communications may (at its discretion), reimburse the cost of the Ticket or replace the disputed Ticket with a Ticket on any current Game of the same price. All disputes shall be referred to the BCLB who shall act as final arbiter in the settlement of the dispute. <br />

12.2. The remedy in Rule 12.1 will be the Player’s sole and exclusive remedy, and any reimbursement or replacement will fully discharge Dynamic Mobile Communications from any liability in respect of such a dispute (subject to Rule 12.3). Dynamic Mobile Communications will not be liable to pay any interest in respect of any reimbursement made under this Rule 12. <br />

12.3. Dynamic Mobile Communications may withhold payment of a Prize and/or make an equivalent payment into court until any dispute has been resolved. <br />

12.4. Any legal disputes will be automatically submitted to the BCLB. <br />

                        </div>
                    </div>
                </div>
            </div>



            <div className="section__title">
                <h4 style={{ color: "white" }}>
                General
                </h4>
            </div>
            <div className="heightlight__tab">
                <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                    <div className="container">
                        <div className="row mb-2 text-white">
                            
                        13.1. Any person who Tickets, or submits a Ticketing slip for validation, or who claims a Prize in whatever capacity, agrees to be bound by the provisions of any applicable legislation, these Rules (all as amended from time to time) and any other rules or procedures, statements, or explanations Dynamic Mobile Communications may issue in respect of that Game Played via Mobile Money. <br />

13.2. Dynamic Mobile Communications may change these Rules, the relevant Game Procedures, and any Game Specific Rules applicable to the Games played via Mobile Money at any time. These changes will be effective immediately from the date of their publication on the Site (or any earlier time Dynamic Mobile Communications states), or on notification to You that the changes have taken place (whichever takes place sooner) and will apply to Tickets played after the date on which the changes become effective, and/or Entries bought before that date if reasonable in the circumstances. Notification can be by SMS, email, Web Account notification, post, or any other form of communication reasonably considered and decided by Dynamic Mobile Communications You agree that You will be bound by the changes when You next play a Game Played via Mobile Money, when you next access Your Account, when you claim a Prize after the changes have become effective, or (where relevant) when You expressly accept the changes, whichever takes place first. <br />

13.3. If any provision (or part of a provision) or rule mentioned above is decided by a court of the Laws of the Republic of Kenya to be void and/or unenforceable, that decision will only affect the particular provision (or part of the provision) and will not, in itself, make any other provisions void or unenforceable <br />

13.4. You may not assign or otherwise transfer (in whole or in part) Your rights and/or obligations under these Rules. <br />

13.5. You agree that when You contact the Customer Care Team, You will supply any Games-Related Information required by Dynamic Mobile Communications. <br />

13.6. Privacy: The Company will respect Your privacy and shall always make efforts to preserve your information in strict confidence. However, the Company shall not be liable for any loss of data. Your personal information will not be disclosed to third parties except: where required for the processing of your requests; under duty or legal obligation; for enforcement of the Terms & Conditions; or for the protection of the rights or property of the company. Note: personal information provided may be disclosed to a credit reference bureau who may keep records of such information. <br />

13.7. Force Majeure: The Company is not responsible in any way for any consequences resulting from major incidents beyond the Company’s control, such as: strikes, terrorist activities, political crises, wars, natural catastrophes, saturation of the telecommunication networks, cyber-attacks, hacking, etc. <br />

13.8. Intellectual Property: All content on our Site, SMS, Mobile service, and all other media and electronic platforms is owned by the Company, its affiliates and third party contractors, and is protected by international intellectual property and copyright laws and regulations, including: products, logos, trademarks, service marks, trading names, etc. No part of the Site, SMS, or Mobile service may be copied. <br />
                        </div>
                    </div>
                </div>
            </div>


            <div className="section__title">
                <h4 style={{ color: "white" }}>
                Jurisdiction
                </h4>
            </div>
            <div className="heightlight__tab">
                <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                    <div className="container">
                        <div className="row mb-2 text-white">
                            
                        This agreement and the contractual relationship Ticket between you and the Company shall be governed by these Terms and Conditions and construed in accordance with the relevant and applicable Laws of the Republic of Kenya.

                        </div>
                    </div>
                </div>
            </div>



            <div className="section__title">
                <h4 style={{ color: "white" }}>
                Definitions
                </h4>
            </div>
            <div className="heightlight__tab">
                <div className="nav b__bottom" id="nav-tabheight" role="tablist">
                    <div className="container">
                        <div className="row mb-2 text-white">
                            
                        Account: An account maintained by a Player on Dynamic Mobile Communications’s Computer System. <br />

                      TICKET: The entry mechanism into the game. <br />

                      Dynamic Mobile Communications: a company incorporated in Kenya. <br />

                      Dynamic Mobile Communications’s Computer System: The Random Number Generator used by or on behalf of Dynamic Mobile Communications from time to time to operate LOTTO games, administer Accounts, facilitate Entries and pay Prizes. <br />

                      BCLB: Betting Control and Licensing Board <br />

                      Claim Period: A period of up to 28 days after the draw date. <br />

                      Customer Service Team: Dynamic Mobile Communications’s helpline for Players. Telephone: 0703065032 Email info@mezizlotto.com. <br />

                      Draw: The process which results in the random selection by Dynamic Mobile Communications of a set of Winning Numbers for a Game. <br />

                      Draw Procedures: The draw procedures that apply to a Draw, as determined by Dynamic Mobile Communications from time to time. <br />

                      Identification Information: Information used to establish Your identity, including information required by Dynamic Mobile Communications to confirm the authenticity of that information. Identification Information could include any element of the Games-Related Information and any information or documents You provide when claiming a Prize. Accepted forms of identification are: ID, Kenyan Driving License and Passport. <br />

                      Lucky Pick: A selection of numbers which are randomly selected by the RNG System on Your behalf for the purposes of making a Ticket. <br />

                      Mobile Money: Safaricom Mpesa, Airtel Money.

                      Privacy Policy: Dynamic Mobile Communications’s policy relating to the use and storage of Your personal information as amended from time to time. <br />

                      Prize: An Interactive Prize. Reference in these Rules to payment of Prizes includes the awarding of non-cash Prizes. <br />

                      Prize Structure: The value of Prizes and odds of winning in an individual Game as determined by Dynamic Mobile Communications and set out in the Game Procedures for the relevant Game. <br />

                      Procedures: A written document issued by Dynamic Mobile Communications for a particular Game Played Online in addition to these Rules, that includes the Game name, the Ticket, how to play, how a Prize is won, the dates and/or frequency of Draws, the Game Prize Structure, how Prize winners are determined, and any other relevant information. <br />

                      Selection: A set of numbers chosen by You (whether chosen by Yourself or via Lucky Dip) for the purpose of buying a Ticket. <br />

                      Site: The website www.mezizbet.com <br />

                      Ticket: The textual or numerical representation of Your Ticket. <br />

                      Ticket Number: The alphanumeric reference included as part of a Ticket which identifies and is unique to a particular Ticket and which is recorded in Dynamic Mobile Communications’s Computer System and, for Entries bought using Your Account, in Your Transaction History. <br />

                      Validation Requirements: Dynamic Mobile Communications’s Entry validation requirements referred to in Rule 6 or as otherwise determined by Dynamic Mobile Communications from time to time. <br />

                      Winning Ticket: A Ticket which entitles You to a Prize and which meets all the Validation Requirements.  <br />

                      Winning Numbers: The numbers used to determine the Winning Entries for the Draw. <br />

                      Copyright © 2023 Dynamic Mobile Communications <br />

                        </div>
                    </div>
                </div>
            </div>

            
          </div>
        </div>
        <h1 className="text-center">All winnings on LOTTO are subject to 20% Tax.</h1>
      </div>
    </>
  );
};
export default Body;
