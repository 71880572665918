import React, { useState, useRef } from "react";
import IMG1 from "../../assets/img/posters/1.png";
import IMG2 from "../../assets/img/posters/2.png";
import IMG3 from "../../assets/img/posters/3.png";
import IMG4 from "../../assets/img/posters/4.png";
import "./Style.css";
import Ads from "./Ads";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";


export const Card = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const phoneNumberInputRef = useRef(null);
    const [formData, setFormData] = useState({
        country: "254",
        phone_number: "",
        amount: "",
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleQuickAmount = (amount) => {
        setFormData({ ...formData, amount: amount });
    };

    // Function to focus on the phone number input field
    const focusPhoneNumberInput = () => {
        phoneNumberInputRef.current.focus();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        let data = {
            phone_number: `${formData.country}${formData.phone_number}`,
            amount: formData.amount,
            account: 'test'
        };

        try {
            const response = await axios.post('https://www.chamayamapatano.or.ke/api/lotto/', data, {
                headers: {
                  'Content-Type': 'application/json',
                },
            });
            if (response.data.ResponseCode === "0") {
                setMessage({ type: 'warning', text: "Request sent successfully. Check your phone to complete the transaction. After a successful transaction, you'll receive a SMS with the ticket number." });
                setFormData({
                    country: "254",
                    phone_number: "",
                    amount: "",
                });
            } else {
                setMessage({ type: 'warning', text: response.data.errorMessage });
            }
        } catch (error) {
            setMessage({ type: 'warning', text: 'Failed to send request.' });
        } finally {
            setTimeout(() => {
                setMessage(null);
            }, 20000);
            setLoading(false);
        }
    };



    return (
        <>
        
            <section className="main__body__area">
                <div className="container">
                    <div className="row g-0 no-gutters">
                        <div className="col-md-4">
                            <div className="dashboard__body__wrap">
                                <div className="deposit__complate">
                                    <h3 style={{color: 'white'}}>
                                        Play straight from <br />
                                        M-Pesa!
                                    </h3>
                                    {message && (
                                        <p className={`message ${message.type === 'warning' ? 'warning' : 'danger'}`}>
                                            {message.text}
                                        </p>
                                    )}
                                    <div className="deposit__wallet">
                                        <div className="deopsit__wallet__items">
                                        <p>Payment Provider</p>
                                        <div className="usd__change">
                                            <p className="">M-pesa</p>
                                        </div>
                                        </div>
                                    </div>

                                    <form onSubmit={handleSubmit}>
                                        <div className="single-input mb__20">
                                            <div className="form__grp">
                                                <div className="d-flex">
                                                    <select
                                                        name="country"
                                                        id="country"
                                                        value={formData.country}
                                                        className=""
                                                        required
                                                        onChange={handleChange}
                                                    >
                                                        <option value="254">+254</option>
                                                    </select>
                                                    <input
                                                        ref={phoneNumberInputRef}
                                                        type="number"
                                                        name="phone_number"
                                                        placeholder="Phone Number"
                                                        value={formData.phone_number}
                                                        onChange={handleChange} required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="single-input">
                                            <input
                                                type="number"
                                                name="amount"
                                                value={formData.amount}
                                                placeholder="Amount KES"
                                                min="50"
                                                step="any"
                                                onChange={handleChange} required
                                            />
                                        </div>
                                        <ul className="quick-value" style={{ marginLeft: "-30px" }}>
                                        {[50, 100, 300, 500].map((amount) => (
                                            <li
                                            key={amount}
                                            onClick={() => handleQuickAmount(amount)}
                                            >
                                            <h5>{amount}</h5>
                                            </li>
                                        ))}
                                        </ul>
                                        <div className="btn-area">
                                            <button
                                                type="submit"
                                                className="cmn--btn"
                                                disabled={loading}
                                            >
                                                {loading ? (
                                                    <Spinner animation="border" variant="light" />
                                                ) : (
                                                    <span>Play Now</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <Ads />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row g-0">
                        <div className="casino__tab__area">
                            <div className="tab-content" id="nav-tabCcasino">
                                <div
                                className="tab-pane mt__30 fade text-white show active"
                                id="casinot8"
                                role="tabpanel"
                                aria-labelledby="casinot1"
                                tabIndex="0"
                                >
                                    <div className="row g-4 pb-60">
                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                            <div className="casino__box">
                                                <img src={IMG1} alt="game" style={{borderRadius: '30%'}} />
                                                {/* <Link to="#" className="live" style={{borderTopLeftRadius: '25px'}} onClick={focusPhoneNumberInput}>
                                                    Every day
                                                </Link> */}
                                                <div className="casino__overlay">
                                                    <button onClick={focusPhoneNumberInput}
                                                        type="submit"
                                                        className="cmn--btn"
                                                    >
                                                        <span>CHEZA</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                            <div className="casino__box">
                                                <img src={IMG2} alt="game" style={{borderRadius: '30%'}} />
                                                <div className="casino__overlay">
                                                    <button onClick={focusPhoneNumberInput}
                                                        type="submit"
                                                        className="cmn--btn"
                                                    >
                                                        <span>CHEZA</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                            <div className="casino__box">
                                                <img src={IMG3} alt="game" style={{borderRadius: '30%'}} />
                                                <div className="casino__overlay">
                                                    <button onClick={focusPhoneNumberInput}
                                                        type="submit"
                                                        className="cmn--btn"
                                                    >
                                                        <span>CHEZA</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                                            <div className="casino__box">
                                                <img src={IMG4} alt="game" style={{borderRadius: '30%'}} />
                                                <div className="casino__overlay">
                                                    <button onClick={focusPhoneNumberInput}
                                                        type="submit"
                                                        className="cmn--btn"
                                                    >
                                                        <span>CHEZA</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Card;
