import React from 'react';
import HelmetContainer from '../../components/Helmet/HelmetContainer';


export const Winners = () => {
    return (
        <>
            <HelmetContainer title="Meziz Lotto | Winners" />
            <section className="main__body__area purple-top-border">
                <div className="container-fluid p-3">
                    <div className="row g-0 justify-content-center">
                        <div className="col-xxl-9 col-xl-9 col-lg-9 justify-content-center">
                            <div className="main__body__wrap left__right__space">
                                <div className="dashboard__body__wrap">
                                    <h3 className="account__head mb__30">Winners</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Winners;