import React from 'react';
import LogoImg from "../assets/logo.png";
import RightArrowImg from "../assets/img/footer/rightarrow.png";
import { Link } from 'react-router-dom';



function Footer(props) {
    return (
        <>
        <footer className="footer__section pt-60">
            <div className="container">
                <div className="footer__top pb-60">
                    <div className="row g-5">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay="0.9s"
                            style={{ visibility: "visible", animationDelay: "0.9s", animationName: "fadeInUp" }}>
                            <div className="widget__items">
                                <div className="footer-head">
                                    <a href="/" className="footer-logo">
                                        <img src={LogoImg} alt="f-logo" />
                                    </a>
                                </div>
                                <div className="content-area">
                                    <h6>
                                        Follow Us
                                    </h6>
                                    <ul className="social">
                                        <li>
                                            <Link to="https://www.facebook.com/share/v3qcYANYofXQGrZH/?mibextid=qi2Omg" className="icon" target='_blank'>
                                                <i className="fa-brands fa-facebook-f"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.instagram.com/mezizbet_kenya?igsh=dHhpdjFraHN6czd0" className="icon" target='_blank'>
                                                <i className="fa-brands fa-instagram"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/" className="icon">
                                                <i className="fa-brands fa-linkedin-in"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://x.com/BetMeziz?t=s4kDHhPi5ZXQG9IddC7nOQ&s=09" className="icon" target='_blank'>
                                                <i className="fa-brands fa-twitter"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.tiktok.com/@mezizbetkenya?_t=8nXolMWVWhJ&_r=1" className="icon" target='_blank'>
                                                <i className="fa-brands fa-tiktok"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-2 col-lg-3 col-md-6 col-sm-6 wow fadeInUp" data-wow-delay="0.7s"
                            style={{ visibility: "visible", animationDelay: "0.7s", animationName: "fadeInUp" }}>
                            <div className="widget__items">
                                <div className="footer-head">
                                    <h3 className="title">
                                    Learn More 
                                    </h3>
                                </div>
                                <div className="content-area">
                                <ul className="quick-link">
                                        <li>
                                            <a href="/terms-and-conditions" className="text-white">
                                                <img src={RightArrowImg} alt="angle" /> Terms & conditions
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/privacy-policy" className="text-white">
                                                <img src={RightArrowImg} alt="angle" /> Privacy policy
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/faq" className="text-white">
                                                <img src={RightArrowImg} alt="angle" /> Frequently asked questions
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-6 col-sm-3 wow fadeInUp" data-wow-delay="0.5s"
                            style={{ visibility: "visible", animationdelay: "0.5s", animationname: "fadeInUp" }}>
                            <div className="widget__items">
                                <div className="footer-head">
                                    <h3 className="title">
                                        Contact
                                    </h3>
                                </div>
                                <div className="content-area">
                                    <ul className="quick-link">
                                        <li>
                                            <a href="#" className="text-white">
                                                <img src={RightArrowImg} alt="angle" /> Telephone: +254743000011
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="text-white">
                                                <img src={RightArrowImg} alt="angle" /> Email: info@mezizlotto.com
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-xl-5 col-lg-4 col-md-6 col-sm-9 wow fadeInUp" data-wow-delay="0.2s"
                            style={{ visibility: "visible", animationdelay: "0.2s", animationname: "fadeInUp" }}>
                            <div className="widget__items">
                                <div className="footer-head">
                                    <h3 className="title">
                                        Legal & Compliance
                                    </h3>
                                </div>

                                <p className="text-white">
                                    Under 18 are strictly forbidden from using services on this website
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom">
                    <p className="text-white">
                    © 2024 mezizlotto.com | All Rights Reserved 
                    </p>
                </div>
            </div>
        </footer>
        </>
    );
}

export default Footer;