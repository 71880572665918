import React from "react";
import HelmetContainer from '../../components/Helmet/HelmetContainer';

export const Results = () => {
  return (
    <>
      <HelmetContainer title="Meziz Lotto | Results" />
      <section className="main__body__area purple-top-border">
            <div className="container-fluid p-3">
                <div className="row g-0 justify-content-center">
                    <div className="col-xxl-9 col-xl-9 col-lg-9 justify-content-center">
                        <div className="main__body__wrap left__right__space">
                            <div className="dashboard__body__wrap">
                                <h3 className="account__head mb__30">Results</h3>
                            </div>


                            <div className="casinoform__tabe">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Draw ID</th>
                                            <th>Time</th>
                                            <th>Winning Numbers</th>
                                            <th>Winners</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>29615</td>
                                            <td>Tue 23 Apr 2024 11:00pm</td>
                                            <td>23 , 33 , 24 , 28 , 6 , 18</td>
                                            <td>View winners</td>
                                        </tr>
                                        <tr>
                                            <td>29614</td>
                                            <td>Tue 23 Apr 2024 10:00pm</td>
                                            <td>5,591 USD</td>
                                            <td>View winners</td>
                                        </tr>
                                        <tr>
                                            <td>29613</td>
                                            <td>Tue 23 Apr 2024 09:00pm</td>
                                            <td>13 , 30 , 6 , 2 , 18 , 36</td>
                                            <td>View winners</td>
                                        </tr>
                                        <tr>
                                            <td>29612</td>
                                            <td>Tue 23 Apr 2024 08:00pm</td>
                                            <td>3 , 15 , 17 , 27 , 20 , 19</td>
                                            <td >View winners</td>
                                        </tr>
                                        <tr>
                                            <td>29611</td>
                                            <td>Tue 23 Apr 2024 07:00pm</td>
                                            <td>36 , 4 , 31 , 23 , 25 , 8</td>
                                            <td>View winners</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
};
export default Results;
