import React from 'react';
import MezizLogo from '../assets/logo.png'


function Header() {
    const handleShowMenu = () => {
        const menu = document.querySelector('.main-menu');
        const menu_icon = document.querySelector('.header-bar');
        menu.classList.toggle('active');
        menu_icon.classList.toggle('active');
    }

    return (
        <>
            <header className="header-section">
                <div className="container-fluid p-3">
                    <div className="header-wrapper">
                        <div className="menu__left__wrap">
                            <div className="logo-menu px-2">
                                <a href="/" className="logo">
                                    <img src={MezizLogo} alt="logo"/>
                                </a>
                            </div>
                            <div className="lang d-flex align-items-center px-2" >
                                <div className="header-bar d-lg-none" onClick={handleShowMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                            <ul className="main-menu">
                                <li>
                                    <a href="/">
                                        <span>Play Now</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/how-to-play">
                                        <span>How to play</span>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="/results">
                                        <span>Results</span>
                                    </a>
                                </li> */}
                                {/* <li>
                                    <a href="/winners">
                                        <span>Winners</span>
                                    </a>
                                </li> */}
                                <li>
                                    <a href="/contact">
                                        <span>Contact Us</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;