import React from "react";
import AdSlider from "../components/sliders/adSlider";
import Card from "../components/Lotto/Card";
import HelmetContainer from '../components/Helmet/HelmetContainer';
function Home() {
  return (
    <>
        <HelmetContainer title="Meziz Lotto | Home" />
        <section className="main__body__area purple-top-border">
            <div className="container">
                <div className="row g-0">
                    <div className="promotions__wrap mt__30 pb-60">
                        <div className="row g-4">
                            <Card />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Home;
