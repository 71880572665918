import React from 'react'
import IMG1 from "../../assets/img/sliders/1.png";
import IMG2 from "../../assets/img/sliders/2.png";
import IMG3 from "../../assets/img/sliders/3.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination, Navigation } from "swiper/modules";
import { Card as BCARD } from "react-bootstrap";
import { Link } from 'react-router-dom';

export const Ads = () => {
    return (
        <> 
            <div className="deposit__complate_ad">
                <Swiper
                    freeMode={true}
                    grabCursor={true}
                    modules={[FreeMode, Autoplay, Pagination, Navigation]}
                    className={"mySwiper"}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    spaceBetween={30}
                    autoplay={{ delay: 3000 }}
                >
                    <SwiperSlide className="">
                        <Link to="#" className="p-0 m-0 overflow-hidden">
                            <BCARD.Img
                            variant="top"
                            src={IMG1}
                            alt="Mezizbet lotto"
                            // style={{ height: "500px", borderRadius: "5px" }}
                            ></BCARD.Img>
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide className="">
                        <Link to="#" className="p-0 m-0 overflow-hidden">
                            <BCARD.Img
                            variant="top"
                            src={IMG2}
                            alt="Mezizbet lotto"
                            // style={{ height: "500px", borderRadius: "5px" }}
                            ></BCARD.Img>
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide className="">
                        <Link to="#" className="p-0 m-0 overflow-hidden">
                            <BCARD.Img
                            variant="top"
                            src={IMG3}
                            alt="Mezizbet lotto"
                            // style={{ height: "500px", borderRadius: "5px" }}
                            ></BCARD.Img>
                        </Link>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}
export default Ads;
